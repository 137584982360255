import authService from '../services/auth.services.js';

export default class Request {

    uploadFile = (file) => {
        const formData = new FormData()
        formData.append('file', file)
        const idUser = authService.getIdUser();

        return fetch(`${process.env.REACT_APP_API}/resprem`, {
            method: 'post',
            headers: {
                'User-ID': idUser,
            },
            body: formData,
        });
        
    }


}

// Definir como 'rodando'
// Numero de linhas como 0
// Numero progresso como 0
// Antes requisição 