export const respremApiServices = {
    async resetProgress(userID) {
        fetch('/reiniciar_progresso', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'User-ID': userID
            },
            body: JSON.stringify({})
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Erro: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                console.log('Sucesso:', data);
            })
            .catch(error => {
                console.error('Erro na requisição:', error);
            });
    }
}